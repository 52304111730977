import {Database} from "../../utils/services/DBClass";
import {AccountManager} from "../../utils/services/accountManager";
import {startServiceWorker} from "../../utils/ServiceWorker/domServiceWorkerUtilities";
import {FarmManager} from "../../utils/services/farmManager";
import {ReportManager} from "../../utils/services/reportManager";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {firebaseApp} from "../../utils/Firebase/firebaseApp";
import {wait} from "../../utils/utilities";

export {AccountManager, ReportManager, Database}

const URL_PARAMS = new URLSearchParams(window.location.href.split("?")[1])

async function onload() {
    // Check that all required services are available
    const login_form = document.getElementById("login_form") as HTMLDivElement
    if (URL_PARAMS.has("configure_client")) {
        login_form.innerHTML = "Downloading essential data...<br>Please <strong>do not</strong> close this tab."
        return
        // window.location.href = URL_PARAMS.get("redirect_uri") || "/snapshot"
    }

    if (typeof indexedDB === "object" &&
        "serviceWorker" in navigator) {
        // Set up the local database
        login_form.innerHTML = "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><p>Setting up local database...</p>"

        await Database.waitForConnection()
        login_form.innerHTML = "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><p>Checking for an already logged-in user...</p>"
        if (await AccountManager.get() !== undefined && !URL_PARAMS.has("configure_client")) {
            // A user is logged in, so redirect them to the home page
            window.location.href = "/snapshot"
            return
        }

        login_form.innerHTML = "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><p>Waiting for Service Worker to be ready...</p>"

        // The registration was successful
        login_form.innerHTML =
            "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><input type=\"text\" placeholder=\"Email Address\" id=\"email\" class=\"login_field\"><input type=\"password\" placeholder=\"Password\" id=\"password\" class=\"login_field\"><input type=\"submit\" hidden><button onclick='SnapshotAPI.login()' class='primary_button' id='login_btn' type='button'>Login</button><p><button onclick='SnapshotAPI.forgot_pwd(this)' type='button' style='width: 100%' class='secondary_button' id='forgot_btn'>Forgot Password</button></p>"

        Database.events.dbError = () => {
            login_form.innerHTML = "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><p>ERROR: Failed to create a local database. This is a requirement.</p>"
        }
    }
    else {
        login_form.innerHTML = "<h1><img src='/assets/images/snapshot_icon.png' style='display:inline-block;height: 24pt;margin-bottom:-3px;'> Snapshot</h1><p>Unfortunately, this browser does not meet the minimum criteria for Snapshot to run. Please try another browser or device.</p>"
    }

    login_form.onsubmit = (e) => {
        e.preventDefault()
        login()
    }

    // if (navigator.onLine) {
    //     document.body.innerHTML += "<br>You are ONLINE!"
    // } else {
    //     document.body.innerHTML += "<br>You are OFFLINE!"
    // }
}

onload()
export {onload}

export async function login() {
    // Change loading button and make it temporarily inactive
    let login_btn = document.getElementById("login_btn") as HTMLButtonElement
    login_btn.innerHTML = "<i class=\"fa-solid fa-spinner loading\"></i>"
    login_btn.classList.add("inactive")

    try {
        await AccountManager.signInEmail(
            (document.getElementById("email") as HTMLInputElement).value,
            (document.getElementById("password") as HTMLInputElement).value
        )
    } catch(e) {
        console.error(e)
        if (e == 'auth/wrong-password') {
            login_btn.innerHTML = "Invalid username/password"
            login_btn.classList.add("error_button")
            setTimeout(() => {
                login_btn.classList.remove("inactive")
                login_btn.classList.remove("error_button")
                login_btn.innerHTML = "Login"
            }, 3000)
            // }else if (e === "Login failed: no token provided") {
            //     login_btn.innerHTML = "Invalid username/password or verification code. Please try again."
            //     login_btn.classList.add("error_button")
            //     setTimeout(() => {
            //         login_btn.classList.remove("inactive")
            //         login_btn.classList.remove("error_button")
            //         login_btn.innerHTML = "Login"
            //     }, 3000)
            // }
        }
        else if (e === "Login failed: Local database error") {
            login_btn.innerHTML = "An internal error occurred. Please try again"
            login_btn.classList.add("error_button")
            setTimeout(() => {
                login_btn.classList.remove("inactive")
                login_btn.classList.remove("error_button")
                login_btn.innerHTML = "Login"
            }, 3000)
        }
        else if (e === 'auth/multi-factor-auth-required') {
            login_btn.innerHTML = "An internal error occurred. Please try again"
            login_btn.classList.add("error_button")
            setTimeout(() => {
                login_btn.classList.remove("inactive")
                login_btn.classList.remove("error_button")
                login_btn.innerHTML = "Login"
            }, 3000)
        }
        else if (e === 'auth/invalid-verification-code') {
            login_btn.innerHTML = "Invalid verification code. Please try again."
            login_btn.classList.add("error_button");
            (document.getElementById("recaptcha-container-id") as HTMLDivElement).remove()
            setTimeout(() => {
                login_btn.classList.remove("inactive")
                login_btn.classList.remove("error_button")
                login_btn.innerHTML = "Login"
                window.location.reload()
            }, 4000)

        }
        else {
            login_btn.innerHTML = "Google sign in error. Please contract the admin."
            login_btn.classList.add("error_button")
            setTimeout(() => {
                login_btn.classList.remove("inactive")
                login_btn.classList.remove("error_button")
                login_btn.innerHTML = "Login"
            }, 3000)
        }
    }
}

onAuthStateChanged(getAuth(firebaseApp), async (user) => {
    if (!user) return

    if (URL_PARAMS.has("configure_client")) {
        await startServiceWorker()
        await AccountManager.fetchAccountData()
        await FarmManager.sync()
        await ReportManager.sync()
        window.location.href = "/snapshot"
    }
    else {
        if ("Notification" in window && Notification.permission === "default") try {await Notification.requestPermission()} catch (e) {}
        await wait(1000)
        URL_PARAMS.set("configure_client", "true")
        window.location.href = window.location.href.split("?")[0] + "?" + URL_PARAMS.toString()
    }
})

export function forgot_pwd(e: Event) {
    console.log("Forgot password...", e)
    let email = (document.getElementById("email") as HTMLInputElement).value
    let forgot_btn = document.getElementById("forgot_btn") as HTMLDivElement

    if (email.indexOf("@") === -1) {
        forgot_btn.innerHTML = "Invalid email address"
        forgot_btn.classList.add("error_button")
        setTimeout(() => {
            forgot_btn.classList.remove("inactive")
            forgot_btn.classList.remove("error_button")
            forgot_btn.innerHTML = "Forgot Password"
        }, 3000)
    }

    forgot_btn.innerHTML = "<i class=\"fa-solid fa-spinner loading\"></i>"
    forgot_btn.classList.add("inactive")

    AccountManager.forgotPassword(email)
        .then(() => {
            forgot_btn.innerHTML = "Check your emails"
            setTimeout(() => {
                forgot_btn.classList.remove("inactive")
                forgot_btn.innerHTML = "Forgot Password"
            }, 3000)
        })
        .catch(() => {
            forgot_btn.innerHTML = "Error occurred. Please try again"
            forgot_btn.classList.add("error_button")
            setTimeout(() => {
                forgot_btn.classList.remove("inactive")
                forgot_btn.classList.remove("error_button")
                forgot_btn.innerHTML = "Forgot Password"
            }, 3000)
        })
}